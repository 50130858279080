import Contact from "../components/home-ui-components/contact";
import Footer from "../components/home-ui-components/footer";
import { useState, useEffect } from "react";
import axios from "axios";
import Navigation from "../components/nav";
import TestimonyCard from "../components/testimonyCard";
// import { testimonialArr } from "../constants/testimonials";

export default function Review() {
  const [testimonials, setTestimonials] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchTestimonies = async () => {
      try {
        const response = await axios.get(`${apiUrl}/testimonies`);
        if (response.data.success) {
          setTestimonials(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching testimonies:", error);
      }
    };

    fetchTestimonies();
  }, []);
  return (
    <div className=" font-helvetica-neue overflow-hidden">
      <div>
        <Navigation />
      </div>
      <div
        className="flex flex-col justify-center items-center"
        style={{ backgroundColor: "#F4F8FA" }}
      >
        <div className="text-center flex items-center justify-center flex-col max-xs:px-2 pt-20">
          <p className="text-4xl font-semibold mb-4">Review</p>
          <p
            className="inline-block w-[400px] md:w-[450px] lg:w-[500px] px-3 text-sm md:text-base"
            style={{ color: "#6C6C6C" }}
          >
            We believe that the true measure of our success lies in the
            experiences and voices of our valued customers.
          </p>
        </div>
        <div className="grid xl:grid-cols-2 2xl:grid-cols-3 xs:grid-cols-1 place-content-center w-fit gap-10 mt-10 mb-20">
          {testimonials.map((t, index) => {
            return (
              <div className="m-0">
                <TestimonyCard
                  icon={t.image}
                  img={t.image}
                  desc={t.content}
                  name={t.name}
                  title={t.workTitle}
                  rating={t.rating}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="pt-20 pr-4 ">
        <Contact />
      </div>
      <div className="">
        <Footer />
      </div>
    </div>
  );
}
