import { useState, useEffect } from "react";
import CustomerNavigation from "../../components/customer-component/customerNav/nav";
import { Link } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";

export default function CustomerProfile() {
  const [customer, setCustomer] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchCustomerData = async () => {
      const customerId = sessionStorage.getItem("userId");
      try {
        const response = await axios.get(`${apiUrl}/getCustomer/${customerId}`);
        setCustomer(response.data.customer);
        // console.log(customer)
      } catch (error) {
        console.error("Error fetching customer data:", error);
        toast.error("Failed to fetch customer data");
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerData();
  }, []);

  const [fullname, setFullname] = useState(customer.name || "");
  const [postalCode, setPostalCode] = useState(customer.postalCode || "");
  const [phoneNumber, setPhoneNumber] = useState(customer.phoneNumber || "");
  const [profileImage, setProfileImage] = useState(null);
  const [existingProfileImage, setExistingProfileImage] = useState(
    customer.picture || ""
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (customer) {
      setFullname(customer.name);
      setPostalCode(customer.postalCode);
      setPhoneNumber(customer.phoneNumber);
      setExistingProfileImage(customer.picture);
    }
  }, [customer]);

  const handleUpdate = async () => {
    const formData = new FormData();
    formData.append("name", fullname);
    formData.append("postalCode", postalCode);
    formData.append("phoneNumber", phoneNumber);

    // Check if profileImage (new image) is uploaded
    if (profileImage instanceof File) {
      formData.append("image", profileImage);
    } else if (existingProfileImage) {
      // If existingProfileImage is a URL, fetch and convert it to a Blob
      try {
        const response = await fetch(existingProfileImage);
        const blob = await response.blob();
        formData.append("image", blob);
      } catch (error) {
        console.error("Error fetching existing image:", error);
        // Handle error fetching image (optional)
      }
    }

    try {
      setLoading(true);
      const response = await axios.post(
        `${apiUrl}/updateCustomer/${customer._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Customer updated successfully");
    } catch (error) {
      toast.error("Failed to update customer");
      console.error("Error updating customer:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    setProfileImage(event.target.files[0]);
  };

  return (
    <>
      <div className="bg-[#F2F8FE] w-full min-h-screen">
        <CustomerNavigation />

        <div className="2xl:px-48 xl:px-20 lg:px-18 md:px-10 sm:px-6 xs:px-4 pt-16">
          <div className="flex justify-start">
            <Link to="/dashboard">
              <button className="bg-white flex items-center space-x-2 px-3 py-2.5 text-sm md:text-base font-medium text-gray-800 rounded-lg mb-2">
                <FaChevronLeft />
                <span>Back to dashoard</span>
              </button>
            </Link>
          </div>

          <div className="bg-white rounded-lg px-5 py-6 mt-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <h1 className="font-semibold text-xl md:text-2xl">
                  Profile Page
                </h1>
                <p className="text-sm md:text-base font-normal">
                  View and update your account details here.
                </p>
              </div>

              <div>
                <img
                  src={
                    existingProfileImage || "https://via.placeholder.com/150"
                  }
                  alt="Profile"
                  className="w-32 h-32 rounded-full object-cover"
                />

                <div>
                  <label
                    htmlFor="profileImage"
                    className="block text-sm font-medium text-gray-700 mb-2 mt-3"
                  >
                    Profile Image
                  </label>
                  <input
                    type="file"
                    id="profileImage"
                    onChange={handleFileChange}
                    className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                  />
                  {/* {existingProfileImage && (
                    <div className="mt-2">
                      <img
                        src={existingProfileImage}
                        alt="Profile"
                        className="w-24 h-24 rounded-full"
                      />
                    </div>
                  )} */}
                </div>

                <div className="space-y-2">
                  <div>
                    <label
                      htmlFor="fullname"
                      className="block text-sm font-medium text-gray-700 mb-2 mt-3"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      id="fullname"
                      value={fullname}
                      onChange={(e) => setFullname(e.target.value)}
                      className="mt-1 block w-full border border-gray-300 bg-gray-50 rounded-lg px-3 py-2 focus:border-blue-500 duration-150 ring-transparent outline-transparent focus:ring-0 focus:outline-none"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="postalCode"
                      className="block text-sm font-medium text-gray-700 mb-2 mt-3"
                    >
                      Postal Code
                    </label>
                    <input
                      type="text"
                      id="postalCode"
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                      className="mt-1 block w-full border border-gray-300 bg-gray-50 rounded-lg px-3 py-2 focus:border-blue-500 duration-150  ring-transparent outline-transparent focus:ring-0 focus:outline-none"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="phoneNumber"
                      className="block text-sm font-medium text-gray-700 mb-2 mt-3"
                    >
                      Phone Number
                    </label>
                    <input
                      type="text"
                      id="phoneNumber"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className="mt-1 block w-full border border-gray-300 bg-gray-50 rounded-lg px-3 py-2 focus:border-blue-500 duration-150  ring-transparent outline-transparent focus:ring-0 focus:outline-none"
                    />
                  </div>
                </div>
                <div className="flex justify-end space-x-2 mt-4">
                  <button
                    className="bg-[#3da5ec] text-white py-2 px-4 rounded-md hover:bg-[#3394d6]"
                    onClick={handleUpdate}
                  >
                    {loading ? "loading..." : "Update"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
