export default function TestimonyCard({ img, icon, desc, name, title, rating }) {
  return (
    <div className="flex flex-col py-7 px-4 xs:w-[330px] sm:-ml-0 sm:-mr-0 sm:w-[450px] shadow-sm h-fit border border-gray-300 bg-white rounded-lg gap-y-6 font-helvetica-neue">
      <div className="flex justify-between">
        <img src={icon} className="w-[40px] h-[40px] rounded-full" alt={name} />
        <p>{"⭐".repeat(rating)}</p>
      </div>
      <div className="max-w-[450px] text-sm md:text-base">
        <p>{desc}</p>
      </div>
      <div className="flex gap-x-6">
        <img src={img} alt={name} className="rounded-full" width={45} height={45} />
        <div>
          <p className="font-semibold">{name}</p>
          <p>{title}</p>
        </div>
      </div>
    </div>
  );
}
