import { useNavigate } from "react-router-dom";

export default function PricingCard({ image, type, price, desc1 }) {
  const navigate = useNavigate();

  // Function to generate slug
  const generateSlug = (str) => str.toLowerCase().replace(/\s+/g, "-");

  const handleLearnMore = () => {
    const slug = generateSlug(type);
    navigate(`/pricing/${slug}`);
  };

  return (
    <div>
      <div className="">
        <div className="flex flex-col items-center">
          <img src={image} alt="" className="w-[80px] h-[80px]" />
          <p className="font-medium mt-4 mb-2">{type}</p>
          <p className="inline-block text-3xl flex items-center font-bold">
            <span className="text-[24px] font-normal mr-1">€</span>
            <span>{price}</span>
            <p
              className="inline-block text-base font-normal"
              style={{ color: "#828282" }}
            >
              <span className="ml-1">/Hour</span>
            </p>
          </p>
        </div>
        <div className="test-base font-normal">
          {desc1.map((item, index) => (
            <p key={index} className="my-2">
              {item.name}: ${item.price}
            </p>
          ))}
        </div>
        <button
          onClick={handleLearnMore}
          className="py-2.5 px-6 bg-primary-sky mt-6 text-sm font-medium text-white rounded-md"
        >
          Learn more
        </button>
      </div>
    </div>
  );
}