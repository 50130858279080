/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import phone from "../../../../assests/images/dashboardPayment/cashless-payment.svg";
import PaymentModal from "./paymentModal";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import ShowModal from "../../../muiModal/modal";

export default function Payment() {
  const [isOpen, setIsOpen] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    paymentType: "",
    cardNumber: "",
    expiryDate: "",
    cardCvc: "",
  });

  const toggleModal = () => setIsOpen(!isOpen);

  const customerId = sessionStorage.getItem("userId");
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/get-payment-methods/${customerId}`
        );
        setPaymentMethods(response.data.paymentMethods || []);
        // console.log(paymentMethods)
      } catch (error) {
        console.error("Failed to fetch payment methods:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPaymentMethods();
  }, [apiUrl, customerId]);

  const handleAddPaymentMethod = async () => {
    try {
      const response = await axios.post(`${apiUrl}/create-payment-method`, {
        customerId,
        ...formData,
      });
      setPaymentMethods((prev) => [...prev, response.data]); // Append new payment method
    } catch (error) {
      console.error("Failed to add payment method:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // console.log(paymentMethods);

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center mx-2">
          <div className="bg-white flex flex-col items-center text-center w-[600px] rounded-xl shadow-sm mt-20 mb-[80px] px-[30px] pt-[20px] pb-10 max-md:w-full">
            <div className="flex justify-center items-center w-full h-40">
              <div className="loader border-t-4 border-blue-500 w-10 h-10 rounded-full animate-spin"></div>
            </div>
          </div>
        </div>
      ) : paymentMethods.length === 0 ? (
        <>
          <div className="flex justify-center mx-2">
            <div className="bg-white flex flex-col items-center text-center w-[600px] rounded-xl shadow-sm mt-20 mb-[80px] px-[30px] pt-[20px] pb-10 max-md:w-full">
              <img src={phone} alt="" className="w-[150px] my-8" />
              <p className="inline-block text-primary-blurText max-w-[500px]">
                Add new payment methods so you can have more options to make
                payments.
              </p>
              <button
                className="block bg-[#3DA5EC] w-fit text-white px-5 py-3 mt-5 rounded-md"
                onClick={toggleModal}
              >
                Add new payment method
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-center mx-2">
            <div className="bg-white text-center w-full rounded-xl shadow-sm mt-20 mb-[80px] px-[30px] pt-[20px] pb-10 max-md:w-full">
              <div class="flex justify-end mb-2">
                <button
                  className="block bg-[#3DA5EC] w-fit text-white px-3 py-3 text-sm mt-5 rounded-lg font-medium"
                  onClick={toggleModal}
                >
                  Add new payment method
                </button>
              </div>

              <table className="table-auto w-full border border-gray-200">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="px-4 py-2 border">Payment Type</th>
                    <th className="px-4 py-2 border">Card Number</th>
                    <th className="px-4 py-2 border">Expiry Date</th>
                    <th className="px-4 py-2 border">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentMethods.map((method) => (
                    <tr key={method._id}>
                      <td className="px-4 py-2 border">{method.paymentType}</td>
                      <td className="px-4 py-2 border">{method.cardNumber}</td>
                      <td className="px-4 py-2 border">{method.expiryDate}</td>
                      <td className="px-4 py-2 border">
                        <button className="bg-yellow-500 text-white px-3 py-1 rounded mr-2">
                          Edit
                        </button>
                        <button className="bg-red-500 text-white px-3 py-1 rounded">
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {isOpen && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white w-[400px] p-6 rounded-lg shadow-lg">
                <h2 className="text-lg font-semibold mb-4">
                  Add Payment Method
                </h2>
                <div className="space-y-4">
                  <input
                    type="text"
                    name="paymentType"
                    value={formData.paymentType}
                    onChange={handleInputChange}
                    placeholder="Payment Type (e.g., Stripe)"
                    className="w-full px-3 py-2 border rounded"
                  />
                  <input
                    type="text"
                    name="cardNumber"
                    value={formData.cardNumber}
                    onChange={handleInputChange}
                    placeholder="Card Number"
                    className="w-full px-3 py-2 border rounded"
                  />
                  <input
                    type="text"
                    name="expiryDate"
                    value={formData.expiryDate}
                    onChange={handleInputChange}
                    placeholder="Expiry Date (e.g., 2/25)"
                    className="w-full px-3 py-2 border rounded"
                  />
                  <input
                    type="text"
                    name="cardCvc"
                    value={formData.cardCvc}
                    onChange={handleInputChange}
                    placeholder="CVC"
                    className="w-full px-3 py-2 border rounded"
                  />
                </div>
                <div className="mt-4 flex justify-end space-x-3">
                  <button
                    className="bg-gray-500 text-white px-4 py-2 rounded"
                    onClick={toggleModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                    onClick={handleAddPaymentMethod}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {/* <div className="flex justify-center mx-2">
        <div className="bg-white flex flex-col items-center text-center w-[600px] rounded-xl shadow-sm mt-20 mb-[80px] px-[30px] pt-[20px] pb-10 max-md:w-full">
          <p className="font-semibold text-[26px] mb-3">Payment Methods</p>

          {isLoading ? (
            <div className="flex justify-center items-center w-full h-40">
              <div className="loader border-t-4 border-blue-500 w-10 h-10 rounded-full animate-spin"></div>
            </div>
          ) : paymentMethods.length === 0 ? (
            <>
              <img src={phone} alt="" className="w-[150px] my-8" />
              <p className="inline-block text-primary-blurText max-w-[500px]">
                Add new payment methods so you can have more options to make
                payments.
              </p>
              <button
                className="block bg-[#3DA5EC] w-fit text-white px-5 py-3 mt-5 rounded-md"
                onClick={toggleModal}
              >
                Add new payment method
              </button>
            </>
          ) : (
            <div className="w-full">
              <div className="bg-white flex flex-col items-start w-[800px] rounded-xl shadow-sm p-6 mt-5 max-md:w-full">
                <table className="table-auto w-full border border-gray-200">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="px-4 py-2 border">Payment Type</th>
                      <th className="px-4 py-2 border">Card Number</th>
                      <th className="px-4 py-2 border">Expiry Date</th>
                      <th className="px-4 py-2 border">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentMethods.map((method) => (
                      <tr key={method._id}>
                        <td className="px-4 py-2 border">
                          {method.paymentType}
                        </td>
                        <td className="px-4 py-2 border">
                          {method.cardNumber}
                        </td>
                        <td className="px-4 py-2 border">
                          {method.expiryDate}
                        </td>
                        <td className="px-4 py-2 border">
                          <button className="bg-yellow-500 text-white px-3 py-1 rounded mr-2">
                            Edit
                          </button>
                          <button className="bg-red-500 text-white px-3 py-1 rounded">
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button
                  className="block bg-[#3DA5EC] w-fit text-white px-5 py-3 mt-5 rounded-md"
                  onClick={toggleModal}
                >
                  Add new payment method
                </button>
              </div>
            </div>
          )}
        </div>

        {isOpen && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white w-[400px] p-6 rounded-lg shadow-lg">
              <h2 className="text-lg font-semibold mb-4">Add Payment Method</h2>
              <div className="space-y-4">
                <input
                  type="text"
                  name="paymentType"
                  value={formData.paymentType}
                  onChange={handleInputChange}
                  placeholder="Payment Type (e.g., Stripe)"
                  className="w-full px-3 py-2 border rounded"
                />
                <input
                  type="text"
                  name="cardNumber"
                  value={formData.cardNumber}
                  onChange={handleInputChange}
                  placeholder="Card Number"
                  className="w-full px-3 py-2 border rounded"
                />
                <input
                  type="text"
                  name="expiryDate"
                  value={formData.expiryDate}
                  onChange={handleInputChange}
                  placeholder="Expiry Date (e.g., 2/25)"
                  className="w-full px-3 py-2 border rounded"
                />
                <input
                  type="text"
                  name="cardCvc"
                  value={formData.cardCvc}
                  onChange={handleInputChange}
                  placeholder="CVC"
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mt-4 flex justify-end space-x-3">
                <button
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                  onClick={toggleModal}
                >
                  Cancel
                </button>
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                  onClick={handleAddPaymentMethod}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        )}
      </div> */}
    </>
  );
}
