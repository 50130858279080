import { useState, useEffect } from "react";
import axios from "axios";
import BlogCard from "./blogCard";

export default function RenderBlog() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    async function fetchBlogs() {
      try {
        const response = await axios.get("https://klean-up-server-hz1y.onrender.com/v1/api/getAllBlogs");
        if (response.data.success) {
          setBlogs(response.data.data);
        }
      } catch (error) {
        console.error("Failed to fetch blogs:", error);
      }
    }
    fetchBlogs();
  }, []);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 md:grid-cols-3 gap-4 md:gap-10 my-20">
      {blogs.map((blog, index) => (
        <div
          key={index}
          className="bg-white rounded-xl border border-gray-300 overflow-hidden"
        >
          <BlogCard image={blog.image} text={blog.content} aim={blog.title} />
        </div>
      ))}
    </div>
  );
}
