import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { toast } from "react-toastify";

const UpdateCustomerModal = ({ isOpen, onClose, customer }) => {
  const [fullname, setFullname] = useState(customer.name || "");
  const [postalCode, setPostalCode] = useState(customer.postalCode || "");
  const [phoneNumber, setPhoneNumber] = useState(customer.phoneNumber || "");
  const [profileImage, setProfileImage] = useState(null);
  const [existingProfileImage, setExistingProfileImage] = useState(
    customer.picture || ""
  );
  const [loading, setLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (customer) {
      setFullname(customer.name);
      setPostalCode(customer.postalCode);
      setPhoneNumber(customer.phoneNumber);
      setExistingProfileImage(customer.picture);
    }
  }, [customer]);

  const handleUpdate = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", fullname);
    formData.append("postalCode", postalCode);
    formData.append("phoneNumber", phoneNumber);
    
    if (profileImage instanceof File) {
      formData.append("image", profileImage);
    } else if (existingProfileImage) {
      try {
        const response = await fetch(existingProfileImage);
        const blob = await response.blob();
        formData.append("image", blob);
      } catch (error) {
        console.error("Error fetching existing image:", error);
        // Handle error fetching image (optional)
      }
    }

    try {
      setLoading(true);
      const response = await axios.post(
        `${apiUrl}/updateCustomer/${customer._id}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Customer updated successfully");
      onClose();
    } catch (error) {
      toast.error("Failed to update customer");
      console.error("Error updating customer:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    setProfileImage(event.target.files[0]);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="update-customer-modal-title"
      aria-describedby="update-customer-modal-description"
    >
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6 space-y-4">
          <h2
            id="update-customer-modal-title"
            className="text-2xl font-semibold mb-4"
          >
            Update Customer Account
          </h2>
          <form onSubmit={handleUpdate}>
            <div className="space-y-2">
              <div>
                <label
                  htmlFor="fullname"
                  className="block text-sm font-medium text-gray-700 mb-2 mt-3"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  id="fullname"
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                  className="mt-1 block w-full border-b border-gray-300 py-2 focus:border-blue-500 duration-150 ring-transparent outline-transparent focus:ring-0 focus:outline-none"
                />
              </div>
              <div>
                <label
                  htmlFor="postalCode"
                  className="block text-sm font-medium text-gray-700 mb-2 mt-3"
                >
                  Postal Code
                </label>
                <input
                  type="text"
                  id="postalCode"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  className="mt-1 block w-full border-b border-gray-300 py-2 focus:border-blue-500 duration-150  ring-transparent outline-transparent focus:ring-0 focus:outline-none"
                />
              </div>
              <div>
                <label
                  htmlFor="phoneNumber"
                  className="block text-sm font-medium text-gray-700 mb-2 mt-3"
                >
                  Phone Number
                </label>
                <input
                  type="text"
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="mt-1 block w-full border-b border-gray-300 py-2 focus:border-blue-500 duration-150  ring-transparent outline-transparent focus:ring-0 focus:outline-none"
                />
              </div>
              <div>
                <label
                  htmlFor="profileImage"
                  className="block text-sm font-medium text-gray-700 mb-2 mt-3"
                >
                  Profile Image
                </label>
                <input
                  type="file"
                  id="profileImage"
                  onChange={handleFileChange}
                  className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                />
                {existingProfileImage && (
                  <div className="mt-2">
                    <img
                      src={existingProfileImage}
                      alt="Profile"
                      className="w-24 h-24 rounded-full"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-end space-x-2">
              <button
                className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="bg-[#3da5ec] text-white py-2 px-4 rounded-md hover:bg-[#3394d6]"
                type="submit"
              >
                {loading ? "loading..." : "Update"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateCustomerModal;
