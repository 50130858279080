import React from "react";
import Navigation from "../components/nav";
import livingroom from "../assests/images/modern-livingroom.svg";
import { IoMdCheckmark } from "react-icons/io";
import Footer from "../components/home-ui-components/footer";
import one from "../assests/images/01.png";
import two from "../assests/images/02.png";
import three from "../assests/images/03.png";
import four from "../assests/images/04.png";
import { Link,
  //  useNavigate,useLocation 
  } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

export default function BecomeCleaner() {
  // const location = useLocation();
  // const history = useNavigate();
  // const handleNext =()=>{
  //   history('./cleanerRegForm')
  // }

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);


  return (
    <div>
      <div>
        <Navigation />
      </div>
      <ToastContainer />
      <div className="flex text-center font-helvetica-neue mt-20 justify-center items-center">
        <div>
          <p className="text-3xl md:text-5xl font-bold" data-aos="fade-up">
            Welcome to FreshMclean
          </p>
          <p
            className="inline-block max-w-[700px] text-[#5B5B5B] text-sm md:text-base mt-5"
            data-aos="fade-up"
          >
            At FreshMclean, we believe in creating a workplace that values its
            team members as much as it values its clients. Joining our cleaning
            team means becoming a crucial part of a company committed to
            excellence and growth.
          </p>
        </div>
      </div>
      <div
        className="my-20 w-full flex items-center justify-center"
        data-aos="fade-up"
      >
        <img src={livingroom} alt="" className="w-[90%]" />
      </div>
      <div className="flex justify-center text-[30px]  mb-20">
        <p
          className="text-center w-[98%] md:w-[60%] lg:w-[45%] mt-5 font-bold text-xl md:text-3xl"
          data-aos="fade-up"
        >
          Here's why you should consider being a part of the FreshMclean family:
        </p>
      </div>
      <div className="flex flex-col gap-y-10 mb-20">
        <div className="flex flex-col lg:flex-row justify-between gap-10">
          <div
            data-aos="fade-up"
            className="bg-[#EAF4FB] w-fit py-8 lg:py-20 px-8 lg:px-20 rounded-r-[100px] flex-1"
          >
            <div>
              <p className="text-lg lg:text-2xl font-semibold mb-5">
                Fulfilling Work
              </p>
              <p className="flex items-start gap-4">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={20} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  Contribute to creating clean and organized spaces that make a
                  real difference in people's lives.
                </div>
              </p>
              <p className="flex items-start gap-4 mt-5">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={20} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  Experience the satisfaction of seeing the immediate impact of
                  your work on our clients' environments.
                </div>
              </p>
            </div>
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="200"
            className="bg-[#EAFBED] w-fit py-8 lg:py-20 px-8 lg:px-20 rounded-l-[100px] flex-1 flex flex-col"
          >
            <div className="">
              <p className="text-lg lg:text-2xl font-semibold mb-5 pl-4 lg:pl-0">
                Competitive Compensation
              </p>
              <p className="flex items-start gap-4">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={20} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  Enjoy a competitive and fair compensation package that
                  recognizes your hard work and dedication.
                </div>
              </p>
              <p className="flex items-start gap-4 mt-5">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={20} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  Benefit from performance-based incentives and bonuses as you
                  excel in your role.
                </div>
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between gap-10">
          <div
            data-aos="fade-up"
            className="bg-[#F0EAFB] w-fit py-8 lg:py-20 px-8 lg:px-20 rounded-r-[100px] flex-1"
          >
            <div>
              <p className="text-lg lg:text-2xl font-semibold mb-5">
                Supportive Team Environment:
              </p>
              <p className="flex items-start gap-4">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={20} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  Join a team of dedicated and friendly professionals who foster
                  a positive and collaborative work environment.
                </div>
              </p>
              <p className="flex items-start gap-4 mt-5">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={20} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  Receive continuous support and encouragement from your
                  colleagues and supervisors.
                </div>
              </p>
            </div>
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="200"
            className="bg-[#FBFAEA] w-fit py-8 lg:py-20 px-8 lg:px-20 rounded-l-[100px] flex-1"
          >
            <div>
              <p className="text-lg lg:text-2xl font-semibold mb-5 pl-4 lg:pl-0">
                Training and Development:
              </p>
              <p className="flex items-start gap-4">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={24} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  Access comprehensive training programs to enhance your skills
                  and knowledge in cleaning techniques and customer service.
                </div>
              </p>
              <p className="flex items-start gap-4 mt-5">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={24} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  Opportunities for professional growth and advancement within
                  the company.
                </div>
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between gap-10">
          <div
            data-aos="fade-up"
            className="bg-[#FBEAEA] w-fit py-8 lg:py-20 px-8 lg:px-20 rounded-r-[100px] flex-1"
          >
            <div>
              <p className="text-lg lg:text-2xl font-semibold mb-5">
                Employee Benefits:
              </p>
              <p className="flex items-center gap-4">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={20} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  Enjoy a range of employee benefits, including health
                  insurance, wellness programs, and other perks.
                </div>
              </p>
              <p className="flex items-center gap-4 mt-5">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={20} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  Participate in team-building activities and events that
                  strengthen the bond among team members.
                </div>
              </p>
            </div>
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="200"
            className="bg-[#FBEAEA] w-fit py-8 lg:py-20 px-8 lg:px-20 rounded-l-[100px] flex-1"
          >
            <div>
              <p className="text-lg lg:text-2xl font-semibold mb-5 pl-4 lg:pl-0">
                Fulfilling Work
              </p>
              <p className="flex items-center gap-4">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={20} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  Your hard work and commitment will be recognized and
                  appreciated through regular acknowledgments and awards.
                </div>
              </p>
              <p className="flex items-center gap-4 mt-5">
                <div className="px-2 py-1.5 rounded-full bg-white flex justify-center items-center">
                  <IoMdCheckmark color="#1C97EC" size={20} />
                </div>
                <div className="max-w-[600px] text-sm md:text-base font-normal">
                  Be a part of a company culture that values and celebrates the
                  contributions of its team members.
                </div>
              </p>
            </div>
          </div>
        </div>

        <div className="text-center mt-4" data-aos="fade-up">
          <Link
            className="bg-primary-sky hover:bg-blue-600 duration-150 text-white py-3 rounded-lg my-10 text-center px-6"
            to="/cleanerRegForm"
          >
            Apply Now
          </Link>
        </div>
      </div>
      <div className="flex flex-col gap-y-5 mb-20 mx-20">
        <p
          className="text-center font-bold text-xl lg:text-3xl mb-4"
          data-aos="fade-up"
        >
          How it works
        </p>
        <div className="self-start flex gap-x-10" data-aos="fade-up">
          <img alt="one" src={one} className="w-[80px] h-[70px]" />
          <div>
            <p className="font-bold text-[24px]">Fill in the application</p>
            <p className=" inline-block max-w-[400px] text-[#5B5B5B] mt-3">
              At Kleanup, we believe in creating a workplace that values its
              team members as much as it values its clients.
            </p>
          </div>
        </div>
        <div className="self-end flex gap-x-10 items-center" data-aos="fade-up">
          <img alt="two" src={two} className="w-[80px] h-[70px]" />
          <div>
            <p className="font-bold text-[24px]">Interview Scheduling</p>
            <p className=" inline-block max-w-[400px] text-[#5B5B5B] mt-3">
              At Kleanup, we believe in creating a workplace that values its
              team members as much as it values its clients.
            </p>
          </div>
        </div>
        <div
          className="self-start flex gap-x-10 items-center"
          data-aos="fade-up"
        >
          <img alt="three" src={three} className="w-[80px] h-[70px]" />
          <div>
            <p className="font-bold text-[24px]">Onboarding</p>
            <p className=" inline-block max-w-[400px] text-[#5B5B5B] mt-3">
              At Kleanup, we believe in creating a workplace that values its
              team members as much as it values its clients.
            </p>
          </div>
        </div>
        <div className="self-end flex gap-x-10 items-center" data-aos="fade-up">
          <img alt="four" src={four} className="w-[80px] h-[70px]" />
          <div>
            <p className="font-bold text-[24px]">Welcome to kleanup</p>
            <p className=" inline-block max-w-[400px] text-[#5B5B5B] mt-3">
              At Kleanup, we believe in creating a workplace that values its
              team members as much as it values its clients.
            </p>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}
