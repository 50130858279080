import Navigation from "../components/nav";
import WomanCleaner from "../assests/images/woman-cleaning.png";
import RenderValue from "../components/renderValue";
import ExpBg from "../assests/images/experience-bg.png";
import Footer from "../components/home-ui-components/footer";
import Group from "../assests/images/group.png";
import { useNavigate } from "react-router-dom";
import MiniGroup from "../assests/images/miniGroup.png";

import { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

export default function Mission() {
  const navigate = useNavigate();
  const handleGetStarted = async()=>{
    const token = sessionStorage.getItem("token");
    if(!token){
      navigate('/login')
    }
    navigate('/dashboard')
  }

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <div className=" overflow-hidden">
      <div>
        <Navigation />
      </div>
      <div
        className="h-[400px]"
        style={{
          backgroundImage: ` url(${WomanCleaner})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
        }}
      >
        <div className="mission-container h-[400px] flex  font-helvetica-neue text-white lg:px-52 sm:px-16 items-center xs:px-4">
          <div className="flex flex-col">
            <button
              className="px-3 py-1 bg-gray-800 rounded-full mb-2 block w-fit"
              data-aos="fade-up"
            >
              About Us
            </button>

            <p
              className="text-2xl font-semibold inline-block max-w-[400px] mt-4 mb-2"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              KleanUp House Cleaning services at your fingertip
            </p>
            <p
              className="text-sm md:text-base max-w-[500px]"
              style={{ color: "#bbbbbb" }}
              data-aos="fade-up"
              data-aos-delay="500"
            >
              We're dedicated to delivering exceptional cleaning services
              tailored to meet your needs. With years of experience and a team
              of skilled professionals, we take pride in creating clean and
              healthy spaces for our clients.
            </p>
          </div>
        </div>
      </div>
      <div className="flex md:flex-row xs:flex-col h-[340px] lg:px-52 md:px-16 sm:px-16 xs:px-4 max-md:mt-10 mb-24">
        <div className="w-1/2 flex items-center max-md:justify-center max-md:w-full">
          <p
            className="text-base md:text-3xl font-semibold"
            data-aos="fade-right"
          >
            Our mission
          </p>
        </div>
        <div
          data-aos="fade-up"
          className="w-1/2 flex flex-col justify-center md:mt-28 xs:mt-6  md:max-w-[400px] text-base max-md:items-center max-md:w-full"
          style={{ color: "#333333" }}
        >
          <p className="mb-5 text-sm md:text-base">
            Our mission is simple: to provide top-notch cleaning solutions that
            exceed expectations while ensuring customer satisfaction.
          </p>
          <p className="text-sm md:text-base">
            We strive to create pristine environments, contributing to your
            comfort and well-being.
          </p>
        </div>
      </div>
      <div className="bg-[#F4FBFD] py-16 flex justify-center px-4">
        <div className="max-w-[900px]">
          <p
            className="text-base md:text-2xl font-medium mb-10"
            data-aos="fade-up"
          >
            About us
          </p>
          <p
            className="leading-[28px] text-sm md:text-[17px] font-normal text-gray-700"
            data-aos="fade-up"
          >
            At FreshMclean, we value professionalism, reliability, and
            convenience. We meet customer expectations, offer flexible services,
            and prioritize long-term cooperation. Our services are available
            anytime, even early mornings or late evenings, with transparent
            cleaning times to help you plan efficiently.
          </p>

          <p
            className="leading-[28px] text-sm md:text-[17px] font-normal text-gray-700 mt-4"
            data-aos="fade-up"
          >
            We act quickly—arriving within 6 hours of booking—and provide 24/7
            management support for service inquiries or issues. Our goal is to
            save you time while ensuring satisfaction with our work, delivered
            by experienced and trustworthy staff.
          </p>

          <p
            className="leading-[28px] text-sm md:text-[17px] font-normal text-gray-700 mt-3"
            data-aos="fade-up"
          >
            FreshMclean combines expertise, eco-friendly practices, and
            competitive pricing. We use the right products to protect your
            surfaces, extending the life of your furniture and appliances. Let
            us make your life easier—place your first order today!
          </p>
        </div>
      </div>
      <div
        className="h-[1000px] mb-32 max-md:hidden"
        style={{
          backgroundImage: `url(${Group})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></div>
      {/* <div
        className="h-[1000px] mb-32 md:hidden"
        style={{
          backgroundImage: `url(${MiniGroup})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></div> */}
      {/* <div className="flex gap-x-10">
          <img src={WomanMop} alt="" className="h-[600px]" />
          <img src={ManClean} alt="" className=" h-[600px]" />
        </div>
        <div className="flex gap-10">
          <img src={HalfLeft} alt="" className=" h-[400px] w-full" />
          <img src={ManTools} alt="" className=" w-full h-[400px]" />
          <img src={HalfRight} alt="" className="h-[400px] w-full" />
        </div> */}

      {/* <div className="grid grid-cols-2 gap-4 w-full my-20"> */}

      {/* <div className="col-span-2 md:col-span-1">
          <img src={WomanMop} alt="" className="h-[600px] w-[900px]" />
        </div>
        <div className="col-span-2 md:col-span-1">
          <img src={ManClean} alt="" className=" h-[600px] w-[1000px]" />
        </div>
        <div className="col-span-2 md:col-span-2 grid grid-cols-3 gap-4">
          <div>
            <img src={HalfLeft} alt="" className=" h-[400px] w-full" />
          </div>
          <div>
            <img src={ManTools} alt="" className=" w-full h-[400px]" />
          </div>
          <div>
            <img src={HalfRight} alt="" className="h-[400px] w-full" />
          </div>
        </div> */}
      {/* </div> */}
      <div className="bg-black" data-aos="fade-up">
        <RenderValue />
      </div>
      {/* <div className="py-20">
        <Partners />
      </div> */}
      <div
        style={{
          backgroundImage: `url(${ExpBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="h-[550px] px-4 text-center flex flex-col gap-y-10 justify-center items-center"
      >
        <p
          className="text-white text-3xl font-medium max-w-[400px]"
          data-aos="fade-up"
        >
          Experience world-class cleaning with us
        </p>
        <p
          className="text-gray-400 max-w-[500px]"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          Experience the difference in cleanliness and service quality with
          KleanUp. Contact us today to discuss your cleaning needs and let us
          make your space shine!
        </p>
        <button
          className="bg-primary-sky hover:bg-blue-700 duration-150 rounded-lg px-10 py-2.5 text-sm md:text-base text-white"
          onClick={handleGetStarted}
          data-aos="fade-up"
          data-aos-delay="300"
        >
          Get started
        </button>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}
