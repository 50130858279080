import { useState } from "react";

import { GoChevronDown } from "react-icons/go";
import { RxCaretDown, RxChevronUp } from "react-icons/rx";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import UKFlag from "../assests/images/UnitedKingdom.png";
import useTokenStatus from "./hooks/useTokenStatus";
import logo from "../assests/images/FreshMClean-logo PNG 2.png"

export default function Navigation() {
  const [icon, setIcon] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { tokenAvailable } = useTokenStatus();

  const handleToggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleIcon = () => {
    setIcon(!icon);
  };

  return (
    <nav className="bg-white 2xl:px-48 xl:px-20 lg:px-18 md:px-10 sm:px-6 xs:px-4 py-10 shadow-md h-20 z-20">
      <div className=" flex flex-row justify-between items-center -mt-5 hidden-sidebar">
        <div className="flex flex-row max-w-fit lg:gap-2 xl:gap-4 max-md:gap-3 font-normal cursor-pointer">
          <Link to="/">
            <img src={logo} alt="freshNklean logo" className="nav-logo-update"/>
          </Link>
          <Link to="/about">
            <p className="hover:text-blue-500 duration-150 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
              About
            </p>
          </Link>
              <Link to="/price">
                <p className="hover:text-blue-500 duration-150 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                  Pricing
                </p>
              </Link>
          <Link to="/review">
            <p className="hover:text-blue-500 duration-150 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
              Reviews
            </p>
          </Link>
          {/* <Link to="/contact">
            <p className="hover:text-blue-500 duration-150 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
              Contact
            </p>
          </Link> */}
          <Link to="/faqs">
            <p className="hover:text-blue-500 duration-150 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
              FAQS
            </p>
          </Link>
          <Link to="/membership">
            <p className="hover:text-blue-500 duration-150 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
              Become a cleaner
            </p>
          </Link>
        </div>
        <div className="gap-3 flex items-center">
          <Link>
            <div className="flex justify-center items-center gap-1">
              <p className="font-medium text-[16px]">EN</p>
              <img src={UKFlag} alt="flag" />
              <RxCaretDown size={24} />
            </div>
          </Link>
          <div>
            {tokenAvailable ? (
              <Link to="/dashboard">
                <button className="py-2 px-5 border border-gray-300 rounded-xl font-normal m-0 hover:bg-gray-100 transition duration-300 transform hover:-translate-y-1 text-[]">
                  Dashboard
                </button>
              </Link>
            ) : (
              <div className="flex gap-2">
                <Link to="/login">
                  <button className="py-2 px-5 border border-gray-300 rounded-xl font-normal m-0 hover:bg-gray-100 transition duration-300 transform hover:-translate-y-1 text-[]">
                    Login
                  </button>
                </Link>
                <Link to="/signup">
                  <button className="py-2 px-5 rounded-xl font-medium text-white bg-primary-sky m-0 text-center hover:bg-primary-skyDark hover:shadow-md hover:-translate-y-1 transition duration-300">
                    Sign Up
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* mini nav */}

      <div className="show-hamburger  rounded-md flex -mt-5 font-helvetica-neue items-center justify-between mx-10 max-lg:mx-0">
      <img src={logo} alt="freshNklean logo" className="nav-logo-update"/>
        <div className="flex items-center gap-3">
          <div className=" flex items-center">
            <Link>
              <div className="flex justify-center items-center gap-1">
                <p className="font-medium text-[16px]">EN</p>
                <img src={UKFlag} alt="flag" />
                <RxCaretDown size={24} />
              </div>
            </Link>
            <div className="max-[550px]:hidden">
              {tokenAvailable ? (
                <Link to="/dashboard">
                  <button className="py-2 px-5 border border-gray-300 rounded-xl font-normal m-0 hover:bg-gray-100 transition duration-300 transform hover:-translate-y-1 text-[]">
                    Dashboard
                  </button>
                </Link>
              ) : (
                <div className="flex gap-2">
                  <Link to="/login">
                    <button className="py-2 px-5 border border-gray-300 rounded-xl font-normal m-0 hover:bg-gray-100 transition duration-300 transform hover:-translate-y-1 text-[]">
                      Login
                    </button>
                  </Link>
                  <Link to="/signup">
                    <button className="py-2 px-5 rounded-xl font-medium text-white bg-primary-sky m-0 text-center hover:bg-primary-skyDark hover:shadow-md hover:-translate-y-1 transition duration-300">
                      Sign Up
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="ml-auto" onClick={handleIcon}>
            {icon ? <RxCross1 size={22} /> : <GiHamburgerMenu size={22} />}
          </div>
        </div>
      </div>

      {icon && (
        <div className="absolute left-0 text-base max-lg:font-medium w-full   z-10 shadow-lg bg-white text-green-950 show-hamburger py-10 pl-16 max-md:pl-8 max-xs:pl-4">
          <div className="flex flex-col items-start gap-5 bounce dropdown text-black">
            <Link to="/about">
              <div className="cursor-pointer  hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                {" "}
                <p className="cursor-pointer">About</p>
              </div>
            </Link>
            <div className="relative">
              <Link to="/price">
                <div
                  className="flex flex-row gap-2 items-center cursor-pointer hover:bg-gray-200 hover:px-3 hover:rounded-lg px-3 rounded-lg"
                  onClick={handleToggleDropdown}
                >
                  <p className="hover:bg-gray-200 hover:px-0 hover:py-2 hover:rounded-lg px-0 py-2 rounded-lg">
                    Pricing
                  </p>
                  {isDropdownOpen ? (
                    <GoChevronDown color="black" />
                  ) : (
                    <RxChevronUp color="black" />
                  )}
                </div>
              </Link>
              {isDropdownOpen && (
                <div
                  className={`bg-white px-4 py-4 space-y-2 w-[200px] ${
                    isDropdownOpen
                      ? "opacity-100"
                      : "opacity-0 pointer-events-none"
                  }`}
                >
                  <p>Lorem ipsum</p>
                  <p>Lorem ipsum</p>
                  <p>Lorem ipsum</p>
                  <p>Lorem ipsum</p>
                </div>
              )}
            </div>
            <Link to="/review">
              <div className="cursor-pointer flex flex-row gap-2 items-center hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                {" "}
                <p className="">Reviews</p>
              </div>
            </Link>
            {/* <Link to="/contact">
              <div className="cursor-pointer  hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                {" "}
                <p className="">Contacts</p>
              </div>
            </Link> */}

            <Link to="/faqs">
              <div className="cursor-pointer  hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                {" "}
                <p className="">FAQS</p>
              </div>
            </Link>
            <Link to="/membership">
              <p className="hover:bg-gray-200 hover:px-3 hover:py-2 hover:rounded-lg px-3 py-2 rounded-lg">
                Become a cleaner
              </p>
            </Link>
          </div>
          <div className="min-[550px]:hidden mt-5">
            {tokenAvailable ? (
              <Link to="/dashboard">
                <button className="py-2 px-5 border border-gray-300 rounded-xl font-normal m-0 hover:bg-gray-100 transition duration-300 transform hover:-translate-y-1 text-[]">
                  Dashboard
                </button>
              </Link>
            ) : (
              <div className="flex gap-2">
                <Link to="/login">
                  <button className="py-2 px-5 border border-gray-300 rounded-xl font-normal m-0 hover:bg-gray-100 transition duration-300 transform hover:-translate-y-1 text-[]">
                    Login
                  </button>
                </Link>
                <Link to="/signup">
                  <button className="py-2 px-5 rounded-xl font-medium text-white bg-primary-sky m-0 text-center hover:bg-primary-skyDark hover:shadow-md hover:-translate-y-1 transition duration-300">
                    Sign Up
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </nav>
  );
}
