import React, { useState } from "react";
import google from "../../../assests/images/google.svg";
import facebook from "../../../assests/images/facebook.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MiniLoader from "../../preloader/mini-preloader";
import { setHasSignIn } from "../../../redux/features/userSlice";
import { useDispatch } from "react-redux";

import { GoogleLogin } from "react-google-login";

const clientId = '605449671851-bsupcnofrfdmsd0f0qli2m3pgt0khb8j.apps.googleusercontent.com'

export default function LoginForm() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword,] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const togglePasswordVisibility = () => {
  //   setShowPassword(!showPassword);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!formData.email || !formData.password) {
      toast.error("Please enter both email and password.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}/loginCustomer`,
        formData
      );
      if (response.status === 200) {
        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("userId", response.data.id);
        localStorage.setItem("token", response.data.token);
      }
      console.log("Login successful:", response.data);
      toast.success("Login successful!");
      dispatch(setHasSignIn(true));
      history("/dashboard");
    } catch (error) {
      console.error("Login failed:", error);
      toast.error(
        error.response.data.error || "Login failed. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  // Google Signin/Signup
  const onSuccess = async (response) => {
    const { tokenId } = response;

    try {
      const res = await fetch(
        "https://klean-up-server-hz1y.onrender.com/v1/api/googleSignIn",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token: tokenId }),
        }
      );

      if (res.status === 200) {
        const data = await res.json();
        sessionStorage.setItem("token", data.token);
        sessionStorage.setItem("userId", data.id);
        localStorage.setItem("token", data.token);

        // Notify user and dispatch Redux action
        console.log("Google Login successful:", data);
        toast.success("Login successful!");
        dispatch(setHasSignIn(true));

        // Redirecting to dashboard
        navigate("/dashboard");
      } else {
        const errorData = await res.json();
        throw new Error(errorData.message || "Google Sign-In failed");
      }
    } catch (error) {
      console.error("Error during Google Sign-In:", error);
      toast.error("Login failed. Please try again.");
    }
  };

  const onFailure = (response) => {
    if (response.error !== "idpiframe_initialization_failed") {
      console.error("Google Sign-In failed:", response);
      toast.error("Google Sign-In failed. Please try again.");
    } else {
      console.debug("Google Sign-In initialization failed:", response);
    }
  };

  return (
    <div className="flex justify-center bg-blue-600">
      <div className=" w-[500px]">
        <div className="flex justify-center ">
          <p className="text-white text-[30px] font-medium  mt-[100px] mb-[45px] md:mb-[80px]">
            FreshMclean
          </p>
        </div>
        <div className="mb-[100px]">
          <div className="flex justify-end mx-2">
            <Link to="/signup">
              <button className="bg-white px-3 py-2.5 text-sm md:text-base font-medium text-gray-800 rounded-lg mb-2">
                Sign up
              </button>
            </Link>
          </div>
          <form>
            <div className="bg-white flex flex-col p-5 rounded-md mx-2">
              <ToastContainer />

              <div className="space-y-4">
                <div>
                  <label className="mb-1 font-medium text-sm block">
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your email "
                    className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0] bg-[#FAFAFA] py-2 px-5 w-full"
                  />
                </div>

                <div>
                  <label className="mb-1 font-medium text-sm block">
                    Password
                  </label>
                  <div className="flex flex-col">
                    <input
                      type={!showPassword ? "password" : "text"}
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      placeholder="*************"
                      className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0] bg-[#FAFAFA] py-2 px-5 w-full"
                    />
                  </div>
                </div>

                {/* <div className=" ml-auto -mt-7 pr-3 cursor-pointer">
                  {showPassword ? (
                    <AiFillEyeInvisible onClick={togglePasswordVisibility} />
                  ) : (
                    <AiFillEye onClick={togglePasswordVisibility} />
                  )}
                </div> */}
              </div>

              {/* <button
                type="button"
                className="absolute top-1/2 right-3 transform -translate-y-1/2"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
              </button> */}
              {/* </div> */}
              <p className="text-gray-600 hover:text-blue-600 duration-150 font-medium mb-5 mt-2 text-sm">
                <Link to="/forgot-password">Forgot Password?</Link>
              </p>
              <button
                type="submit"
                className="bg-primary-sky text-white py-3 rounded-md text-center"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? <MiniLoader /> : "Signin"}
              </button>

              <hr className="mt-8 mb-4" />

              <p className="text-gray-600 text-sm text-center font-medium mb-3">
                Or sign in with
              </p>

              <div className="flex text-[12px] max-sm:text-[16px] gap-x-2 gap-y-5 max-sm:flex-col max-sm:mx-2">
                {/* <button className="flex-1 bg-[#F4F4F4] px-2 py-3 rounded-md flex gap-x-2 justify-center items-center">
                  <img src={google} alt="google" />
                  <span className='font-medium text-sm'>Google</span>
                </button> */}
                <div className="flex-1">
                  <GoogleLogin
                    clientId={clientId}
                    buttonText="Google"
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    cookiePolicy={"single_host_origin"}
                  />
                </div>
                <button className="flex-1 bg-[#F4F4F4] px-2 py-3 rounded-md flex gap-x-2 justify-center items-center">
                  <img src={facebook} alt="facebook" />
                  <span className="font-medium text-sm">Facebook</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
